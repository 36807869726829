import React, { ComponentType } from "react";

import { OperationVariables, QueryOptions } from "@apollo/client";

import client from "./client";

declare module "react" {
	type LazyExoticComponentExtended<T extends ComponentType> = LazyExoticComponent<T> & {
		preload: () => Promise<{ default: ComponentType<unknown> }>;
	};
}

export const ReactLazyPreload = (
	importStatement: () => Promise<{ default: ComponentType<any> }>,
): React.LazyExoticComponentExtended<React.ComponentType<any>> & {
	preload: () => Promise<{ default: ComponentType<any> }>;
} => {
	const Component = React.lazy(importStatement) as React.LazyExoticComponentExtended<
		ComponentType<any>
	>;

	Component.preload = importStatement;

	return Component;
};

export const preload = async <TVariables extends OperationVariables, T = any>(
	page: ReturnType<typeof ReactLazyPreload>,
	query: QueryOptions<TVariables, T>,
) => {
	try {
		await Promise.all([page.preload(), client.query(query)]);
	} catch (error) {
		console.error(error);
	}
};
