import { Roles } from "@/generated/graphql";

import { z } from "zod";

/**
 * Parses and validates a JWT token
 * @param token JWT token string
 * @returns Parsed and validated token payload or error
 */
export function parseJwt<T>(
	token: string,
	schema: z.ZodSchema<T>,
):
	| {
			data: T;
			error: null;
	  }
	| {
			data: null;
			error: Error;
	  } {
	try {
		const [, base64Payload] = token.split(".");

		if (!base64Payload) {
			throw new Error("Invalid JWT format");
		}

		const base64 = base64Payload.replace(/-/g, "+").replace(/_/g, "/");
		const rawPayload = JSON.parse(window.atob(base64));

		const result = schema.safeParse(rawPayload);

		if (!result.success) {
			return {
				data: null,
				error: new Error("Invalid token payload structure"),
			};
		}

		return {
			data: result.data,
			error: null,
		};
	} catch (error) {
		return {
			data: null,
			error: error instanceof Error ? error : new Error(String(error)),
		};
	}
}

const anonymousJwtSchema = z.object({
	sub: z.string(),
	role: z.literal(Roles.Visitor),
	exp: z.number(),
});

export function getTokenPayload(token: string) {
	const { error, data } = parseJwt(token, anonymousJwtSchema);

	if (error) {
		return null;
	}

	return data;
}
