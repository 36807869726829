import { translations as translationsDE } from "./de/translations";
import { translations as translationsEN } from "./en/translations";
import { home as homeDE } from "./de/home";
import { home as homeEN } from "./en/home";

/**
 * Translations
 */
export const supportedLngs = ["de", "en"] as ["de", "en"];
export type ISupportedLngs = (typeof supportedLngs)[number];
export const fallbackLng: ISupportedLngs = "de";

export const resources = {
	en: {
		translations: translationsEN,
		home: homeEN,
	},
	de: {
		translations: translationsDE,
		home: homeDE,
	},
} as const;

export const ns = ["translations", "home"] as const;
export type INs = (typeof ns)[number];
export const defaultNS: INs = "translations";
