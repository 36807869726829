import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
	main: {
		background: theme.palette.background.default,

		boxSizing: "border-box",
		overflow: "auto",

		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",

		padding: theme.spacing(4),

		"& > *": {
			maxWidth: 375,
			margin: theme.spacing(2, 0),
		},
	},
	logoWrapper: {
		position: "absolute",
		top: theme.spacing(2),
		right: theme.spacing(2),
	},
}));

export default useStyles;
