import Typography, { TypographyProps } from "@mui/material/Typography";

type TypographyComponentProps = {
	as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
	color?: TypographyProps["color"];
	onClick?: TypographyProps["onClick"];
	className?: TypographyProps["className"];
	variant: TypographyProps["variant"];
	textAlign?: TypographyProps["textAlign"];
	variantMapping?: TypographyProps["variantMapping"];
	children?: TypographyProps["children"];
};

const TypographyComponent: React.FC<TypographyComponentProps> = ({
	children,

	...props
}) => {
	return (
		<Typography
			{...props}
			style={{ cursor: props.onClick ? "pointer" : "inherit" }}
			className={props.className}
			color={props.color ?? "primary"}
		>
			{children}
		</Typography>
	);
};
type ITypographyComponentImplementation = Pick<
	TypographyComponentProps,
	"color" | "className" | "variantMapping" | "as" | "onClick" | "textAlign" | "children"
>;

export const H1 = (props: ITypographyComponentImplementation) => (
	<TypographyComponent {...props} variant="h1" variantMapping={{ h1: props.as ?? "h1" }} />
);

export const H2 = (props: ITypographyComponentImplementation) => (
	<TypographyComponent {...props} variant="h2" variantMapping={{ h2: props.as ?? "h2" }} />
);

export const H3 = (props: ITypographyComponentImplementation) => (
	<TypographyComponent {...props} variant="h3" variantMapping={{ h3: props.as ?? "h3" }} />
);

export const H4 = (props: ITypographyComponentImplementation) => (
	<TypographyComponent {...props} variant="h4" variantMapping={{ h4: props.as ?? "h4" }} />
);

export const H5 = (props: ITypographyComponentImplementation) => (
	<TypographyComponent {...props} variant="h5" variantMapping={{ h5: props.as ?? "h5" }} />
);

export const H6 = (props: ITypographyComponentImplementation) => (
	<TypographyComponent {...props} variant="h6" variantMapping={{ h6: props.as ?? "h6" }} />
);

export const CopyLargeHero = (props: ITypographyComponentImplementation) => (
	<TypographyComponent {...props} variant="body1" />
);

export const CopyLarge = (props: ITypographyComponentImplementation) => (
	<TypographyComponent {...props} variant="body2" />
);

export const CopySmallHero = (props: ITypographyComponentImplementation) => (
	<TypographyComponent {...props} variant="subtitle1" as="p" />
);

export const CopySmall = (props: ITypographyComponentImplementation) => (
	<TypographyComponent {...props} variant="subtitle2" as="p" />
);

export const Caption = (props: ITypographyComponentImplementation) => (
	<TypographyComponent {...props} variant="caption" />
);

export default TypographyComponent;
