import { useEffect, useState } from "react";

import * as Sentry from "@sentry/react";
import { useLocation } from "react-router-dom";

import { SENTRY_DSN } from "@/config";

const useSentry = (error: Error) => {
	const hasAlreadySubmittedFeedback = localStorage.getItem(error.message);
	const [userFeedbackOpen, setUserFeedbackOpen] = useState(hasAlreadySubmittedFeedback !== "true");
	const [eventId, setEventId] = useState<string | null>(null);
	const location = useLocation();

	useEffect(() => {
		Sentry.setContext("location", location);

		const eventId = Sentry.captureException(error);

		setEventId(eventId);
	}, [location]);

	const submitFeedbackAsFormData = async (comments: string) => {
		if (eventId === null) return;

		const formData = new FormData();
		formData.append("name", "Unknown");
		formData.append("comments", comments);
		formData.append("email", "unknown@saferspaces.io");

		try {
			await fetch(`https://sentry.io/api/embed/error-page/?dsn=${SENTRY_DSN}&eventId=${eventId}`, {
				method: "POST",
				body: formData,
			});

			localStorage.setItem(error.message, "true");
		} catch (error) {
			console.error(error);
		} finally {
			setEventId(null);
			setUserFeedbackOpen(false);
		}
	};

	// const submitFeedback = async (comments: string) => {
	// 	if (eventId === null) return;

	// 	try {
	// 		await fetch(
	// 			`https://sentry.io/api/0/projects/${SENTRY_ORGANIZATION}/${SENTRY_PROJECT}/user-feedback/`,
	// 			{
	// 				method: "POST",
	// 				headers: {
	// 					"Content-Type": "application/json",
	// 					Authorization: `Bearer 83579dba11774c50b0f965e908666da53e245bbfd9ce49978dfd914e5e9d5943`,
	// 				},
	// 				body: JSON.stringify({
	// 					comments: comments,
	// 					name: "Unknown",
	// 					email: "unknown@saferspaces.io",
	// 					event_id: eventId,
	// 				}),
	// 			},
	// 		);

	// 		localStorage.setItem(error.message, "true");
	// 	} catch (error) {
	// 		console.error(error);
	// 	} finally {
	// 		setEventId(null);
	// 		setUserFeedbackOpen(false);
	// 	}
	// };

	return {
		userFeedbackOpen,
		setUserFeedbackOpen,
		eventId,
		submitFeedback: submitFeedbackAsFormData,
	};
};

export default useSentry;
