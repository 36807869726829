import { PropsWithChildren } from "react";

import useStyles from "./styles";

const Centered = (props: PropsWithChildren) => {
	const classes = useStyles();

	return (
		<>
			<main className={classes.main}>{props.children}</main>
		</>
	);
};

export default Centered;
