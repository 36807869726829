import { useNavigate } from "react-router";

import { H6 } from "@/system/atoms/Typography";
import { PrimaryButton } from "@/system/atoms/Buttons";
import Centered from "@/components/environments/Centered";

import useSentry from "@/hooks/useSentry";

const Fallback404 = (props: { text: string }) => {
	const navigate = useNavigate();

	useSentry(new Error(props.text));

	return (
		<Centered>
			<H6 color="primary" textAlign="center">
				{props.text}
			</H6>

			<PrimaryButton onClick={() => navigate("/")} fullWidth>
				Zurück zur Startseite
			</PrimaryButton>
		</Centered>
	);
};

export default Fallback404;
