import { PropsWithChildren, useState } from "react";

import { useTranslation } from "react-i18next";

import SaferSpacesLogo from "@/components/atoms/SaferSpacesLogo";
import Drawer from "@/components/environments/DefaultLayout/components/Drawer";
import useStyles from "@/components/environments/DefaultLayout/styles";
import IconsButtons from "@/components/atoms/IconsButtons/IconsButtons";
import { useScrollListener } from "@/hooks/useScrollListener";
import { Box, Footer } from "@/system/atoms/Components";
import { LanguageToggle } from "@/system/atoms/LanguageToggle/LanguageToggle";
import { CopySmallHero } from "@/system/atoms/Typography";

const DefaultLayout = (props: PropsWithChildren<{ noMargin?: boolean; headline?: string }>) => {
	const { i18n } = useTranslation();
	const [scale, setScale] = useState(1);
	const [open, setOpen] = useState(false);
	const classes = useStyles({ scale, noMargin: props.noMargin });

	const scrollRef = useScrollListener((current) => () => {
		const scrollTop = current.scrollTop;

		setScale(Math.max((80 - scrollTop) / 80, 0));
	});

	const scaleFactor = 1 - (1 - scale) * 0.5;

	return (
		<section className={classes.background}>
			<header className={classes.header} style={{ height: `${80 * scaleFactor}px` }}>
				<IconsButtons.Menu
					onBackground
					onClick={() => {
						setOpen(true);
					}}
				/>
				<Drawer
					open={open}
					openDrawer={() => {
						setOpen(true);
					}}
					closeDrawer={() => {
						setOpen(false);
					}}
				/>

				<figure style={{ transform: `scale(${scaleFactor})`, transformOrigin: "top right" }}>
					<SaferSpacesLogo height={80} width={80} variant="top-right" />
				</figure>
			</header>

			<main className={classes.main} ref={scrollRef}>
				<article className={classes["main--content"]}>
					{props.headline ? (
						<Box component="div" mb={2}>
							<CopySmallHero>{props.headline}</CopySmallHero>
						</Box>
					) : null}
					{props.children}
				</article>

				<Footer display="flex" justifyContent="center" mt={2} mb={1}>
					<LanguageToggle
						language={i18n.language === "de" ? "de" : "en"}
						onClick={() => {
							i18n.changeLanguage(i18n.language === "de" ? "en" : "de");
						}}
					/>
				</Footer>
			</main>
		</section>
	);
};

export default DefaultLayout;
