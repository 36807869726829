export const home = {
	"Hello!": "Hello!",
	Retry: "Retry",
	"Scan qr code": "Scan qr code",
	"Search nearby": "Search nearby",
	"Waiting to allow location permissions": "Waiting to allow location permissions",
	"User denied Geolocation": "Please active the geolocation permission in your browser.",
	"Welcome to the saferspaces app. Scan a qr code nearby to notify the security staff with your current position":
		"Welcome to the saferspaces app. Scan a qr code nearby to notify the security staff with your current position.",
	"Which place is nearby?": "Which place is nearby?",
} as const;
