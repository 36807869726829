import { ApolloProvider } from "@apollo/client";

import { StyledEngineProvider } from "@mui/material/styles";

import client from "./client";
import Root from "./pages/routes";
import { colorTheme, finalTheme } from "./system/theme";
import { ThemeProvider } from "@/system/theme";

import GlobalStyle from "./GlobalStyles";
import "./AppConfig";
import "./SentryConfig";
import { CustomContentProps, SnackbarProvider } from "notistack";
import { forwardRef } from "react";
import Alert from "@mui/material/Alert";

// (props: CustomContentProps) => {

const Custom = forwardRef<HTMLDivElement, CustomContentProps>((props, forwardedRef) => {
	if (props.variant === "default") {
		return (
			<div ref={forwardedRef}>
				<Alert severity="info">{props.message}</Alert>
			</div>
		);
	}

	return (
		<div ref={forwardedRef}>
			<Alert severity={props.variant}>{props.message}</Alert>
		</div>
	);
});

function App() {
	return (
		<ApolloProvider client={client}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={colorTheme}>
					<ThemeProvider theme={finalTheme}>
						{/* <HighContrastContextProvider> */}
						<GlobalStyle />

						<SnackbarProvider
							Components={{
								info: Custom,
								success: Custom,
							}}
						>
							<Root />
						</SnackbarProvider>
						{/* </HighContrastContextProvider> */}
					</ThemeProvider>
				</ThemeProvider>
			</StyledEngineProvider>
		</ApolloProvider>
	);
}

// const HighContrastContextProvider = (props: { children: ReactNode }) => {
// 	return (
// 		<ThemeProvider
// 			theme={(theme: Theme) =>
// 				createTheme({
// 					...theme,
// 					palette: {
// 						tertiary: {
// 							main: "#000",
// 							dark: "#000",
// 							light: "#000",
// 						},
// 						primary: {
// 							main: "#000",
// 							dark: "#000",
// 							light: "#000",
// 						},
// 						secondary: {
// 							main: "#000",
// 							dark: "#000",
// 							light: "#000",
// 						},
// 						background: {
// 							default: "#fff",
// 							paper: "#fff",
// 						},
// 					},
// 				})
// 			}
// 		>
// 			{props.children}
// 		</ThemeProvider>
// 	);
// };

export default App;
