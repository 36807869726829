import { Caption } from "@/system/atoms/Typography";
import { Theme, makeStyles } from "@/system/theme";
import { ReactNode } from "react";

const styles = makeStyles((theme: Theme) => {
	return {
		banner: {
			position: "fixed",
			top: 0,
			left: 0,
			height: 24,
			width: 128,
			transform: "rotate(-45deg) translate(-64px, 40px)",
			transformOrigin: "top left",
			background: theme.palette.error.main,
			color: "white",
			zIndex: 10000,
			textAlign: "center",
		},
	};
});

export const Banner = (props: { children: ReactNode }) => {
	const classes = styles();

	return (
		<div className={classes.banner}>
			<Caption color="inherit">
				<strong>{props.children}</strong>
			</Caption>
		</div>
	);
};
