import React from "react";
import { Outlet } from "react-router-dom";

export const LazyRoute = () => {
	return (
		<React.Suspense fallback={<></>}>
			<Outlet />
		</React.Suspense>
	);
};
