import Image, { loader } from "./Image";

type SaferSpacesLogoProps = {
	className?: string;
	height?: number;
	width?: number;
	variant: "top-right" | "top-left" | "bottom-right" | "bottom-left";
};

const SaferSpacesLogo = (props: SaferSpacesLogoProps) => {
	let src = "";
	switch (props.variant) {
		case "bottom-right":
			src = "logo/Saferspaces_Logo_RGB_P_Rechts_Unten_fhp14e.png";
			break;
		case "top-left":
			src = "logo/Saferspaces_Logo_RGB_P_Links_Oben_s1bqte.png";
			break;
		case "top-right":
			src = "logo/Saferspaces_Logo_RGB_P_Rechts_Oben_mnduxe.png";
			break;
	}

	return (
		<Image
			className={props.className}
			layout="responsive"
			height={props.height ?? 160}
			width={props.width ?? 160}
			loader={loader}
			src={src}
			alt="Safer Spaces Logo"
		/>
	);
};

export default SaferSpacesLogo;
