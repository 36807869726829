import { createContext, FunctionComponent, ReactNode, useContext } from "react";
import { z } from "zod";

import { ContextException } from "@/exceptions/ContextException";
import useSearchParams from "@/hooks/useSearchParams";

import { Banner } from "@/system/molecules/Banner/Banner";

const QueryParams = {
	mode: z
		.enum(["test", "live"])
		.nullable()
		.transform((value) => {
			return value ?? "live";
		}),
};

const QueryParamsSchema = z.object(QueryParams);

type QueryParams = z.infer<typeof QueryParamsSchema>;

const ModeContext = createContext<{
	params: QueryParams;
	setParams: (params: Partial<QueryParams>) => void;
} | null>(null);

export const ModeContextProvider: FunctionComponent<{
	children: ReactNode;
}> = (props) => {
	const { params, setParams } = useSearchParams(QueryParams);

	return (
		<ModeContext.Provider
			value={{
				params,
				setParams,
			}}
		>
			{params.mode === "test" ? <Banner>LIVE-ANSICHT</Banner> : null}

			{props.children}
		</ModeContext.Provider>
	);
};

export const useModeContext = () => {
	const context = useContext(ModeContext);

	if (!context) {
		throw new ContextException({ context: "ModeContext" });
	}

	return context;
};

export default ModeContextProvider;
