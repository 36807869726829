import Language from "@mui/icons-material/Language";

import { TextButton } from "@/system/atoms/Buttons";
import { FlexBox } from "@/system/atoms/Components";
import { Caption } from "@/system/atoms/Typography";

export const LanguageToggle = (props: { onClick: () => void; language: "de" | "en" }) => {
	return (
		<TextButton onClick={props.onClick} aria-label="">
			<FlexBox component="div" mr={1}>
				<Language />
			</FlexBox>
			<Caption color="inherit">
				<b>{props.language.toUpperCase()}</b>
			</Caption>
		</TextButton>
	);
};
