export const createShadows = Array.from({ length: 24 }).map((_, index) => {
	if (index === 0) {
		return "none";
	}

	const distance = Math.ceil(index / 2);
	const spread = index * 2;

	const opacity = (1 - index / 24) ** 8 * 0.5 + 0.05;

	return `${distance}px ${distance}px ${spread}px 0px rgba(41, 19, 129, ${opacity})`;
}) as [
	"none",
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
	string,
];
