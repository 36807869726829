import { useEffect, useRef } from "react";

export const useScrollListener = (listener: (current: HTMLElement) => (event: Event) => void) => {
	const scrollRef = useRef<HTMLElement | null>(null);

	useEffect(() => {
		const scrollRefCurrent = scrollRef.current;

		if (scrollRefCurrent) {
			scrollRefCurrent.addEventListener("scroll", listener(scrollRefCurrent));
		}

		return () => {
			if (scrollRefCurrent) {
				scrollRefCurrent.removeEventListener("scroll", listener(scrollRefCurrent));
			}
		};
	}, []);

	return scrollRef;
};
