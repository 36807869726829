import React, { PropsWithChildren } from "react";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme: Theme) => {
	return {
		button: {
			borderRadius: "28px",
			padding: theme.spacing(2, 4),
			textTransform: "none",
			fontWeight: "bold",
		},
		dangerButton: {
			borderRadius: "28px",
			border: `solid ${theme.palette.error.main} 1px`,
			color: "white",
			background: theme.palette.error.main,
			padding: theme.spacing(2, 4),
			textTransform: "none",
			fontWeight: "bold",
		},
	};
});

const useRoundStyles = makeStyles((_theme: Theme) => {
	return {
		roundButton: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			borderRadius: ({ size }: { size: number }) => `${size / 2}px`,
			height: ({ size }: { size: number }) => `${size}px`,
			width: ({ size }: { size: number }) => `${size}px`,
			textTransform: "none",
			fontWeight: "bold",
		},
	};
});

type ButtonProps = {
	disabled?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	fullWidth?: boolean;
};

export const PrimaryButton = (props: ButtonProps & PropsWithChildren) => {
	const classes = useStyles();

	return (
		<Button
			color="secondary"
			variant="contained"
			className={classes.button}
			onClick={props.onClick}
			disabled={props.disabled}
			type={props.onClick ? undefined : "submit"}
			fullWidth={props.fullWidth}
			disableElevation
		>
			{props.children}
		</Button>
	);
};

export const PrimaryRoundButton = (props: ButtonProps & PropsWithChildren & { size: number }) => {
	const classes = useRoundStyles({ size: props.size });

	return (
		<Button
			color="secondary"
			variant="contained"
			className={classes.roundButton}
			onClick={props.onClick}
			type={props.onClick ? undefined : "submit"}
			disableElevation
			disabled={props.disabled}
		>
			{props.children}
		</Button>
	);
};

export const SecondaryButton = (props: ButtonProps & PropsWithChildren) => {
	const classes = useStyles();

	return (
		<Button
			color="primary"
			variant="outlined"
			className={classes.button}
			onClick={props.onClick}
			disabled={props.disabled}
			type={props.onClick ? undefined : "submit"}
			fullWidth={props.fullWidth}
			disableElevation
		>
			{props.children}
		</Button>
	);
};

export const TextButton = (props: ButtonProps & PropsWithChildren) => {
	const classes = useStyles();

	return (
		<Button
			className={classes.button}
			onClick={props.onClick}
			type={props.onClick ? undefined : "submit"}
			fullWidth={props.fullWidth}
		>
			<Box display="flex" alignItems="center">
				{props.children}
			</Box>
		</Button>
	);
};

export const DangerButton = (props: ButtonProps & PropsWithChildren) => {
	const classes = useStyles();

	return (
		<Button
			color="error"
			variant="contained"
			className={classes.dangerButton}
			onClick={props.onClick}
			type={props.onClick ? undefined : "submit"}
			fullWidth={props.fullWidth}
		>
			{props.children}
		</Button>
	);
};
