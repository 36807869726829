import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { defaultNS, fallbackLng, ns, resources, supportedLngs } from "./locales/resources";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
	detection: {
		order: ["localStorage", "navigator"],
		lookupLocalStorage: "language",
		caches: ["localStorage"],
	},
	supportedLngs,
});

i18n.use(initReactI18next).init({
	ns,
	defaultNS,
	resources,
	fallbackLng,
	supportedLngs,
	interpolation: {
		escapeValue: false,
	},
	debug: false,
});

export default i18n;
