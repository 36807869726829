import { z } from "zod";
import { useNavigate, useLocation } from "react-router-dom";

const useSearchParams = <T extends { [key: string]: z.ZodType }>(schema: T) => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(search);

	const result: { [param: string]: string | null } = {};

	for (const param of Object.keys(schema)) {
		result[param] = searchParams.get(param);
	}

	return {
		params: z.object(schema).parse(result),
		setParams: (params: { [key in keyof T]?: z.infer<T[key]> }) => {
			const newParams = Object.entries({ ...result, ...params })
				.filter(([key, value]) => {
					return value !== null && value !== undefined;
				})
				.map(([key, value]) => {
					return `${key}=${value}`;
				})
				.join("&");

			navigate({ search: newParams });
		},
	};
};

export default useSearchParams;
