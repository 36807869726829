import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";

import * as Sentry from "@sentry/react";

import { BACKEND_URL, BACKEND_WS_URL, ENVIRONMENT, FRONTEND_URL, NAME, VERSION } from "./config";
import { omitDeep } from "./utils/apollo";
import { automaticallyPersistedQueryLink } from "./client.automaticallyPersistedQueryLink";
import { getTokenPayload } from "./auth";

const authLink = setContext((_, { headers, ...rest }) => {
	const accessToken = sessionStorage.getItem("jwt");

	if (!accessToken) {
		return { headers };
	}

	const payload = getTokenPayload(accessToken);

	if (!payload) {
		return { headers };
	}

	if (payload.exp < Date.now() / 1000) {
		sessionStorage.removeItem("jwt");
		return { headers };
	}

	return {
		headers: {
			authorization: accessToken ? `Bearer ${accessToken}` : "",
			...headers,
			...(rest.preview ? { "x-preview": "true" } : {}),
		},
	};
});

const httpLink = new HttpLink({
	uri: BACKEND_URL,
	credentials: ENVIRONMENT === "development" ? undefined : "include",
	headers: {
		"Access-Control-Allow-Origin": FRONTEND_URL,
	},
});

const linkChain = automaticallyPersistedQueryLink.concat(httpLink);

const wsLink = new WebSocketLink({
	uri: BACKEND_WS_URL,
	options: {
		lazy: true,
		reconnect: true,
	},
});

/**
 * Middlewares
 */
const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);

		return definition.kind === "OperationDefinition" && definition.operation === "subscription";
	},
	wsLink,
	authLink.concat(linkChain),
);

const cleanTypenameLink = new ApolloLink((operation, forward) => {
	if (operation.variables && !operation.variables.file) {
		operation.variables = omitDeep(operation.variables, "__typename");
	}

	return forward(operation);
});

const sentryMiddleware = new ApolloLink((operation, forward) => {
	Sentry.addBreadcrumb({
		category: "query",
		message: operation.operationName,
		level: "info",
	});

	Sentry.setContext("query", {
		operationName: operation.operationName,
		variables: JSON.stringify(operation.variables),
	});

	return forward(operation);
});

/**
 * Create client
 */
const client = new ApolloClient({
	name: NAME,
	version: VERSION,
	link: ApolloLink.from([cleanTypenameLink, sentryMiddleware, splitLink]),
	cache: new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					me: {
						merge(existing, incoming) {
							return incoming;
						},
					},
				},
			},
		},
	}),
});

export default client;
