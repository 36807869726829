import { ReactNode } from "react";

import MuiButton from "@mui/material/Button";
import React from "react";

type ButtonProps = {
	children: ReactNode;
	disabled?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	fullWidth?: boolean;
	size?: "small" | "large" | "medium" | undefined;
};

type BaseButtonProps = ButtonProps & {
	as?: any;
	"aria-label"?: string;
	variant?: "text" | "contained" | "outlined" | undefined;
	color?:
		| "secondary"
		| "inherit"
		| "primary"
		| "success"
		| "error"
		| "info"
		| "warning"
		| undefined;
};

export const BaseButton = (props: BaseButtonProps) => {
	return <MuiButton disableElevation {...props} aria-label="" />;
};

export const PrimaryButton = (props: ButtonProps) => {
	return (
		<BaseButton
			color="secondary"
			variant="contained"
			size={props.size ?? "medium"}
			fullWidth={props.fullWidth}
			onClick={props.onClick}
			disabled={props.disabled}
		>
			{props.children}
		</BaseButton>
	);
};

export const SecondaryButton = (props: ButtonProps) => {
	return (
		<BaseButton
			color="primary"
			variant="outlined"
			size={props.size ?? "medium"}
			fullWidth={props.fullWidth}
			onClick={props.onClick}
			disabled={props.disabled}
		>
			{props.children}
		</BaseButton>
	);
};

export const TextButton = (props: ButtonProps) => {
	return (
		<BaseButton onClick={props.onClick} fullWidth={props.fullWidth} disabled={props.disabled}>
			{props.children}
		</BaseButton>
	);
};

export const DangerButton = (props: ButtonProps) => {
	return (
		<BaseButton
			color="error"
			variant="contained"
			size={props.size ?? "medium"}
			fullWidth={props.fullWidth}
			onClick={props.onClick}
			disabled={props.disabled}
		>
			{props.children}
		</BaseButton>
	);
};
