export type Environments = "production" | "staging" | "development" | "test";
export type Version = `${number}.${number}.${number}`;

export const ENVIRONMENT = process.env.NODE_ENV as Environments;

//
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL ?? "http://localhost:3001";
export const FRONTEND_ADMIN_URL =
	process.env.REACT_APP_FRONTEND_ADMIN_URL ?? "http://localhost:3000";
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL ?? "http://localhost:5001/graphql";
export const BACKEND_WS_URL = process.env.REACT_APP_BACKEND_WS_URL ?? "ws://localhost:5001/ws";

// Sentry
export const SENTRY_DSN =
	"https://e1144294ae094050a208989d64f5b9bc@o969245.ingest.sentry.io/6030861";
export const SENTRY_ORGANIZATION = "safer-spaces";
export const SENTRY_PROJECT = "webapp-user";
export const SENTRY_AUTH_TOKEN = "47e20638ad0f4489910e517ef31acbcd9573144e5ce64a81a76482c2ae2b8745";

export const GOOGLE_MAPS_API_KEY = "AIzaSyC0A3WE2SS3IaxZ6ipgPU6tltVzp1XAO_M";

export const VERSION = process.env.REACT_APP_VERSION as Version;
export const NAME = "web";
