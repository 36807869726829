import React from "react";
import ReactDOM from "react-dom";
// import "leaflet/dist/leaflet.css";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root"),
);

reportWebVitals();
