export const loader = ({
	src,
	width,
	quality,
}: {
	src: string;
	width: number;
	quality?: number;
}) => {
	return `https://res.cloudinary.com/duaez8yfb/image/upload/v1628080719/saferspaces/${src}?w=${width}&q=${
		quality || 100
	}`;
};

type ImageProps = {
	className?: string;
	layout?: string;
	height?: number;
	width: number;
	loader: (args: { src: string; width: number; quality?: number }) => string;
	src: string;
	alt: string;
};

const Image = (props: ImageProps) => {
	return (
		<img
			className={props.className}
			style={{ verticalAlign: "middle", maxWidth: "100%" }}
			height={props.height}
			width={props.width}
			alt={props.alt}
			src={props.loader({ src: props.src, quality: 100, width: props.width })}
		/>
	);
};

export default Image;
