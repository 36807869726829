import IconButton from "@mui/material/IconButton";

import { useTheme } from "@mui/material/styles";

import ChevronLeft from "@mui/icons-material/ChevronLeft";

import { ReactComponent as MenuIcon } from "@/assets/menu.svg";

type IconButtonProps = {
	onBackground?: boolean;
	onClick: () => void;
};

export const Menu = (props: IconButtonProps) => {
	const theme = useTheme();

	return (
		<IconButton color="inherit" onClick={props.onClick} aria-label="Open menu">
			<MenuIcon stroke={props.onBackground ? theme.palette.primary.main : "white"} />
		</IconButton>
	);
};

export const Back = (props: IconButtonProps) => {
	return (
		<IconButton color="inherit" onClick={props.onClick} aria-label="Go back">
			<ChevronLeft />
		</IconButton>
	);
};

const IconsButtons = { Menu, Back };

export default IconsButtons;
